<template>
  <div v-if="!loading">
    <header class="flex flex-col">
      <div class="hidden lg:flex flex-row items-center">
        <h1 v-if="!editMode" class="mr-4">{{ getSearchLabel(search.id) }}</h1>
        <span
          v-if="!editMode && interactionCountBySearchId(search.id) > 0"
          class="text-primary-40"
        >
          <router-link
            :to="{
              name: 'ProjectInteractions',
              params: {
                chatId: findLastInteractionBySearchId(search.id).chat_id
              }
            }"
          >
            <Icon name="bell" size="md" class="interaction-bell" />
            {{ interactionCountBySearchId(search.id) }}
          </router-link>
        </span>
        <div
          v-if="editMode"
          class="flex items-center p-1 bg-white rounded-xxl w-1/2"
        >
          <input
            type="text"
            v-model="newLabel"
            :placeholder="getSearchLabel(search.id)"
            class="px-2 py-1 w-full outline-none"
          />
        </div>
      </div>
      <div class="self-end mb-3 flex">
        <Button
          v-if="!editMode && active"
          appearance="invert"
          size="sm"
          @click="edit()"
          >Modifier</Button
        >
        <Toggle
          :labels="{ checked: $t('active'), unchecked: $t('inactive') }"
          :checked="active"
          @input="onToggleSearchStatus"
        />
      </div>
    </header>
    <Wrapper :disabled="!active">
      <Panel>
        <div class="mt-4">
          <PropertyInformations
            :search="search"
            :with-panel="false"
            :edit-mode="editMode"
          />
        </div>
        <div class="mt-8">
          <PropertyDetails :search="search" :edit-mode="editMode" />
        </div>
        <div class="mt-8">
          <PropertyLocations
            :map-url="search.map_url"
            :cities="search.cities"
          />
        </div>
        <div class="text-right">
          <Button
            v-if="editMode"
            appearance="invert"
            size="sm"
            @click="cancel()"
            >Annuler</Button
          >
          <Button v-if="editMode" shape="pills" size="sm" @click="update()"
            >Valider</Button
          >
        </div>
      </Panel>
    </Wrapper>

    <Snackbar v-model="showSnackbar" v-bind="snackbarProps"></Snackbar>

    <Modal
      v-show="showModal"
      :type="active ? 'success' : 'danger'"
      :title="
        active
          ? $t('pages.search.enable_title')
          : $t('pages.search.disable_title')
      "
      :actions="{
        cancelVisible: true,
        cancelLabel: 'Annuler',
        actionVisible: true,
        actionLabel: active
          ? $t('pages.search.enable_action')
          : $t('pages.search.disable_action'),
        backdropClickEnabled: false
      }"
      @action="update()"
      @cancel="cancelActive()"
    >
      {{
        active
          ? $t("pages.search.enable_label")
          : $t("pages.search.disable_label")
      }}
    </Modal>

    <Modal
      v-show="showCannotModal"
      type="danger"
      :title="$t('pages.search.cannot_enable_title')"
      :actions="{
        cancelVisible: false,
        cancelLabel: '',
        actionVisible: true,
        actionLabel: $t('pages.search.cannot_enable_action'),
        backdropClickEnabled: false
      }"
      @action="showCannotModal = false"
    >
      {{ $t("pages.search.cannot_enable_label") }}
    </Modal>
  </div>
  <div v-else>
    <Loading />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

import PropertyInformations from "@/components/property/PropertyInformations.vue";
import PropertyLocations from "@/components/property/PropertyLocations.vue";
import PropertyDetails from "@/components/property/PropertyDetails.vue";
import Wrapper from "@/components/Wrapper.vue";
import Loading from "../components/Loading.vue";

export default {
  name: "ProjectSearch",

  components: {
    PropertyInformations,
    PropertyLocations,
    PropertyDetails,
    Wrapper,
    Loading
  },

  data: function() {
    return {
      loading: true,
      showSnackbar: false,
      showModal: false,
      showCannotModal: false,
      hasError: false,
      editMode: false,
      newLabel: ""
    };
  },

  computed: {
    ...mapState("search", ["selectedSearch"]),
    ...mapGetters("search", ["getSearchLabel", "canActivateSearch"]),
    ...mapGetters("interaction", [
      "interactionCountBySearchId",
      "findLastInteractionBySearchId"
    ]),
    ...mapGetters("user", ["gtmProfile"]),

    search() {
      return this.selectedSearch;
    },

    details() {
      return this.search ? this.search.details : null;
    },

    active() {
      return this.search ? this.search.active : false;
    },
    map_url() {
      return this.search ? this.search.map_url : "";
    },
    cities() {
      return this.search ? this.search.cities : [];
    },

    snackbarProps() {
      let props = {
        variant: "success",
        text: "Votre recherche a été mise à jour !",
        action: "OK",
        x: "center",
        y: "top",
        timeout: 5
      };

      if (this.hasError) {
        props = {
          ...props,
          variant: "error",
          text: "Erreur ! Impossible de modifier votre recherche"
        };
      }

      return props;
    }
  },

  methods: {
    ...mapActions("search", {
      targetSearch: "target",
      fetchSearch: "fetch",
      updateSearch: "update"
    }),

    onToggleSearchStatus() {
      if (!this.search.active && !this.canActivateSearch) {
        this.showCannotModal = true;
      } else {
        this.search.active = !this.search.active;
        this.showModal = true;
      }
    },

    async update() {
      this.search.details.label = this.newLabel;

      this.updateSearch({
        id: this.search.id,
        payload: this.search
      })
        .catch(() => {
          this.hasError = true;
          this.showSnackbar = true;
          this.showModal = false;
          this.editMode = false;
        })
        .finally(() => {
          this.hasError = false;
          this.showSnackbar = true;
          this.showModal = false;
          this.editMode = false;

          this.$gtm.trackEvent({
            event: this.search.active
              ? "estate-search-edit"
              : "estate-search-disable",
            eventCategory: "Estate Search",
            eventAction: this.search.active
              ? "Estate Search Edit"
              : "Estate Search Disable",
            eventLabel: this.gtmProfile,
            eventValue: "",
            noninteraction: false
          });
        });
    },

    cancelActive() {
      this.search.active = !this.search.active;
      this.showModal = false;
    },

    edit() {
      this.editMode = true;
      this.newLabel = this.search.details.label;
    },

    async cancel() {
      await this.fetchSearch({ id: this.$route.params.searchId });
      this.editMode = false;
    }
  },

  async mounted() {
    if (this.$route.params.searchId) {
      await this.fetchSearch({ id: this.$route.params.searchId });
      if (this.$route.params.action == "edit") {
        this.edit();
      }
      this.loading = false;
    } else this.loading = false;
  },

  beforeRouteUpdate(to, from, next) {
    if (to.params.searchId)
      this.targetSearch({ id: to.params.searchId }).then(() => {
        next();
      });
    else next(false);
  }
};
</script>
