<template>
  <div class="wrapper" :class="{ disabled: disabled }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Wrapper",

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="postcss" scoped>
.wrapper {
  &.disabled {
    @apply pointer-events-none select-none cursor-not-allowed;

    filter: grayscale(1);
  }
}
</style>
