<template>
  <div class="flex flex-col justify-center py-4 text-center md:py-0">
    <span
      class="font-lato text-gray-smoke md:text-md"
      :class="[
        size === 'large' ? 'mb-3 text-3xl' : 'mb-1 text-xl',
        this.icon ? 'h-8' : ''
      ]"
      :title="value"
    >
      <component
        :is="iconOrSpan"
        :name="icon"
        class="h-8 m-auto"
        :class="[this.icon ? '' : 'pt-3']"
      >
        {{ value }}
      </component>
    </span>
    <span class="font-sans text-base text-gray-elephant leading-5 md:text-sm">
      {{ label | capitalize }}
    </span>
  </div>
</template>

<script>
export const TYPES = {
  NORMAL: "normal",
  LARGE: "large"
};

export default {
  name: "ProjectSummariesItem",

  props: {
    label: {
      type: String
    },

    icon: {
      type: String
    },

    value: {
      type: [String, Number]
    },

    size: {
      type: String,
      default: TYPES.NORMAL,
      validator: value => Object.values(TYPES).indexOf(value) !== -1
    }
  },

  computed: {
    iconOrSpan() {
      return this.icon ? "Icon" : "span";
    }
  }
};
</script>
