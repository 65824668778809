<template>
  <div>
    <!-- mobile -->
    <div class="md:hidden">
      <div
        class="grid grid-cols-2 md:grid-cols-5 grid-rows-3 md:grid-rows-1 gap-3 md:gap-2"
      >
        <div
          class="flex flex-col justify-center py-4 text-center md:py-0 bg-gray-cloud bg-opacity-50 rounded-xl"
        >
          <span class="font-lato text-gray-smoke md:text-md mb-1 text-xl">
            <span class="h-8 m-auto pt-3">
              {{ $t(`property.${search.rent ? "rent" : "buy"}`) }}
            </span>
          </span>
          <span
            class="font-sans text-base text-gray-elephant leading-5 md:text-sm"
          >
            {{ $t("property_labels.intention") }}
          </span>
        </div>
        <div
          class="flex flex-col justify-center py-4 text-center md:py-0 bg-gray-cloud bg-opacity-50 rounded-xl"
        >
          <span class="font-lato text-gray-smoke md:text-md mb-1 text-xl">
            {{ $t(`property.type.${search.type}`) }}
          </span>
          <span
            class="font-sans text-base text-gray-elephant leading-5 md:text-sm"
          >
            {{ $t("property_labels.property_type") }}
          </span>
        </div>
        <div
          class="flex flex-col justify-center py-4 text-center md:py-0 bg-gray-cloud bg-opacity-50 rounded-xl"
        >
          <span class="font-lato text-gray-smoke md:text-md mb-1 text-xl">
            <span v-if="!editMode" class="h-8 m-auto pt-3">
              {{ search.details.room_count }}
            </span>
            <div v-else class="m-auto bg-gray-light rounded-xxl w-3/4">
              <input
                type="number"
                v-model="search.details.room_count"
                min="1"
                class="self-center text-center bg-transparent w-full outline-none px-2"
              />
            </div>
          </span>
          <span
            class="font-sans text-base text-gray-elephant leading-5 md:text-sm"
          >
            {{ room_label }}
          </span>
        </div>
        <div
          class="flex flex-col justify-center py-4 text-center md:py-0 bg-gray-cloud bg-opacity-50 rounded-xl"
        >
          <span class="font-lato text-gray-smoke md:text-md mb-1 text-xl">
            <span v-if="!editMode" class="h-8 m-auto pt-3">
              {{ `${search.details.area} m²` }}
            </span>
            <div v-else class="m-auto bg-gray-light rounded-xxl w-3/4">
              <input
                type="number"
                v-model="search.details.area"
                min="5"
                step="5"
                class="self-center text-center bg-transparent w-full outline-none px-2"
              />
            </div>
          </span>
          <span
            class="font-sans text-base text-gray-elephant leading-5 md:text-sm"
          >
            {{ $t("property_labels.area") }} {{ editMode ? " (m²)" : "" }}
          </span>
        </div>
        <div
          class="flex flex-col justify-center py-4 text-center md:py-0 bg-gray-cloud bg-opacity-50 rounded-xl col-span-2 md:col-span-1 row-span-2"
        >
          <span class="font-lato text-gray-smoke md:text-md mb-3 text-3xl">
            <span v-if="!editMode" class="h-8 m-auto pt-3">
              {{ budget }}
            </span>
            <div v-else class="m-auto bg-gray-light rounded-xxl w-3/4">
              <input
                type="number"
                v-model="search.details.budget"
                min="5000"
                step="5000"
                class="self-center text-center bg-transparent w-full outline-none px-2"
              />
            </div>
          </span>
          <span
            class="font-sans text-base text-gray-elephant leading-5 md:text-sm"
          >
            {{ $t("property_labels.budget") }} {{ editMode ? "(€)" : "" }}
          </span>
        </div>
      </div>
    </div>
    <!-- desktop -->
    <div class="hidden md:block">
      <component :is="withPanel ? 'Panel' : 'div'">
        <div class="grid grid-cols-5">
          <div
            class="flex flex-col justify-center py-4 text-center md:py-0 items-center border-secondary-10 border-r"
          >
            <span class="font-lato text-gray-smoke md:text-md mb-1 text-xl">
              <span class="h-8 m-auto pt-3">
                {{ $t(`property.${search.rent ? "rent" : "buy"}`) }}
              </span>
            </span>
            <span
              class="font-sans text-base text-gray-elephant leading-5 md:text-sm"
            >
              {{ $t("property_labels.intention") }}
            </span>
          </div>
          <div
            class="flex flex-col justify-center py-4 text-center md:py-0 items-center border-secondary-10 border-r"
          >
            <span class="font-lato text-gray-smoke md:text-md mb-1 text-xl">
              {{ $t(`property.type.${search.type}`) }}
            </span>
            <span
              class="font-sans text-base text-gray-elephant leading-5 md:text-sm"
            >
              {{ $t("property_labels.property_type") }}
            </span>
          </div>
          <div
            class="flex flex-col justify-center py-4 text-center md:py-0 items-center border-secondary-10 border-r"
          >
            <span class="font-lato text-gray-smoke md:text-md mb-1 text-xl">
              <span v-if="!editMode" class="h-8 m-auto pt-3">
                {{ search.details.room_count }}
              </span>
              <div v-else class="m-auto bg-gray-light rounded-xxl w-3/4">
                <input
                  type="number"
                  v-model="search.details.room_count"
                  min="1"
                  @change="
                    search.details.room_count = Math.abs(
                      search.details.room_count
                    )
                  "
                  class="self-center text-center bg-transparent w-full outline-none px-2"
                />
              </div>
            </span>
            <span
              class="font-sans text-base text-gray-elephant leading-5 md:text-sm"
            >
              {{ room_label }}
            </span>
          </div>
          <div
            class="flex flex-col justify-center py-4 text-center md:py-0 items-center border-secondary-10 border-r"
          >
            <span class="font-lato text-gray-smoke md:text-md mb-1 text-xl">
              <span v-if="!editMode" class="h-8 m-auto pt-3">
                {{ `${search.details.area} m²` }}
              </span>
              <div v-else class="m-auto bg-gray-light rounded-xxl w-3/4">
                <input
                  type="number"
                  v-model="search.details.area"
                  min="5"
                  step="5"
                  @change="search.details.area = Math.abs(search.details.area)"
                  class="self-center text-center bg-transparent w-full outline-none px-2"
                />
              </div>
            </span>
            <span
              class="font-sans text-base text-gray-elephant leading-5 md:text-sm"
            >
              {{ $t("property_labels.area") }} {{ editMode ? " (m²)" : "" }}
            </span>
          </div>
          <div
            class="flex flex-col justify-center py-4 text-center md:py-0 items-center"
          >
            <span class="font-lato text-gray-smoke md:text-md mb-1 text-xl">
              <span v-if="!editMode" class="h-8 m-auto pt-3">
                {{ budget }}
              </span>
              <div v-else class="m-auto bg-gray-light rounded-xxl w-3/4">
                <input
                  type="number"
                  v-model="search.details.budget"
                  min="5000"
                  step="5000"
                  @change="
                    search.details.budget = Math.abs(search.details.budget)
                  "
                  class="self-center text-center bg-transparent w-full outline-none px-2"
                />
              </div>
            </span>
            <span
              class="font-sans text-base text-gray-elephant leading-5 md:text-sm"
            >
              {{ $t("property_labels.budget") }} {{ editMode ? "(€)" : "" }}
            </span>
          </div>
        </div>
      </component>
    </div>
  </div>
</template>

<script>
import ProjectSummariesItem from "@/components/project/ProjectSummariesItem.vue";

export default {
  name: "PropertyInformations",

  components: {
    ProjectSummariesItem
  },

  props: {
    withPanel: {
      type: Boolean,
      default: true
    },

    search: {
      type: Object
    },

    editMode: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    budget() {
      return new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(this.search.details.budget);
    },

    room_label() {
      return this.search.type == 1
        ? this.$tc(
            "property_labels.bedroom_count",
            this.search.details.room_count
          )
        : this.$tc(
            "property_labels.room_count",
            this.search.details.room_count
          );
    }
  }
};
</script>
