<template>
  <div>
    <div class="font-sans text-sm text-gray-smoke">
      {{ this.citiesList }}
    </div>
    <div class="my-4">
      <img
        :src="this.fullMapUrl"
        onerror="this.style.display='none'"
        class="w-full m-auto md:w-2/3"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "PropertyLocations",

  props: {
    mapUrl: {
      type: String
    },

    cities: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    citiesList() {
      let list = "";
      _.forEach(this.cities, function(city) {
        if (city.name != undefined) {
          list = list + city.name;
          if (
            city.postal_codes != undefined &&
            city.postal_codes[0] != undefined
          ) {
            list = list + " (" + city.postal_codes[0] + ")";
          }
          list = list + ", ";
        }
      });
      if (list.length > 0) {
        list = list.slice(0, -2);
      }
      return list;
    },

    fullMapUrl() {
      return process.env.VUE_APP_CMN_URL + "/" + this.mapUrl;
    }
  }
};
</script>
