<template>
  <div class="flex flex-col lg:flex-row">
    <div class="lg:w-1/2">
      <div class="flex flex-row justify-start mb-3">
        <div
          class="self-start rounded-full text-primary transform -translate-y-2px"
        >
          <Icon size="md" name="home" />
        </div>
        <div class="ml-2 flex flex-col items-start font-sans text-left">
          <span class="mb-1 text-xs text-gray-elephant">
            {{ $t("property_labels.property_type") }}
          </span>
          <span
            v-if="!editMode"
            class="text-sm text-gray-smoke whitespace-pre-line mr-2"
          >
            {{
              search.details.property_type == null
                ? $t("not_applicable")
                : $t(
                    `property.details.property_type.${search.details.property_type}`
                  )
            }}
          </span>
          <div
            v-else
            class="flex items-center p-1 bg-gray-light rounded-xxl w-full"
          >
            <select
              v-model="search.details.property_type"
              class="pr-1 bg-transparent font-sans font-medium text-sm text-gray-smoke outline-none"
            >
              <option disabled value="">Choisissez</option>
              <option
                v-for="key in Object.keys($t(`property.details.property_type`))"
                v-bind:key="key"
                v-bind:value="key"
              >
                {{ $t(`property.details.property_type.${key}`) }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-start mb-3">
        <div
          class="self-start rounded-full text-primary transform -translate-y-2px"
        >
          <Icon size="md" name="euro-sign" />
        </div>
        <div class="ml-2 flex flex-col items-start font-sans text-left">
          <span class="mb-1 text-xs text-gray-elephant">
            {{ $t("property_labels.funding_type") }}
          </span>
          <span
            v-if="!editMode"
            class="text-sm text-gray-smoke whitespace-pre-line mr-2"
          >
            {{
              search.funding_type == null
                ? $t("not_applicable")
                : $t(`property.funding_type.${search.funding_type}`)
            }}
          </span>
          <div
            v-else
            class="flex items-center p-1 bg-gray-light rounded-xxl mr-4"
          >
            <select
              v-model="search.funding_type"
              class="pr-1 bg-transparent font-sans font-medium text-sm text-gray-smoke outline-none"
              style="width: 100%; max-width: 100%;"
            >
              <option disabled value="">Choisissez</option>
              <option
                v-for="key in Object.keys($t(`property.funding_type`))"
                v-bind:key="key"
                v-bind:value="key"
              >
                {{ $t(`property.funding_type.${key}`) }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-start mb-3">
        <div
          class="self-start rounded-full text-primary transform -translate-y-2px"
        >
          <Icon size="md" name="paint-roller" />
        </div>
        <div class="ml-2 flex flex-col items-start font-sans text-left">
          <span class="mb-1 text-xs text-gray-elephant">
            {{ $t("property_labels.works_type") }}
          </span>
          <span
            v-if="!editMode"
            class="text-sm text-gray-smoke whitespace-pre-line mr-2"
          >
            {{
              search.details.works_type == null
                ? $t("not_applicable")
                : $t(`property.details.works_type.${search.details.works_type}`)
            }}
          </span>
          <div
            v-else
            class="flex items-center p-1 bg-gray-light rounded-xxl w-full"
          >
            <select
              v-model="search.details.works_type"
              class="pr-1 bg-transparent font-sans font-medium text-sm text-gray-smoke outline-none"
            >
              <option disabled value="">Choisissez</option>
              <option
                v-for="key in Object.keys($t(`property.details.works_type`))"
                v-bind:key="key"
                v-bind:value="key"
              >
                {{ $t(`property.details.works_type.${key}`) }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-start mb-3">
        <div
          class="self-start rounded-full text-primary transform -translate-y-2px"
        >
          <Icon size="md" name="photo-size-select" />
        </div>
        <div class="ml-2 flex flex-col items-start font-sans text-left">
          <span class="mb-1 text-xs text-gray-elephant">
            {{ $t("property_labels.ground_area_type") }}
          </span>
          <span
            v-if="!editMode"
            class="text-sm text-gray-smoke whitespace-pre-line mr-2"
          >
            {{
              search.details.ground_area_type == null
                ? $t("not_applicable")
                : $t(
                    `property.details.ground_area_type.${search.details.ground_area_type}`
                  )
            }}
          </span>
          <div
            v-else
            class="flex items-center p-1 bg-gray-light rounded-xxl w-full"
          >
            <select
              v-model="search.details.ground_area_type"
              class="pr-1 bg-transparent font-sans font-medium text-sm text-gray-smoke outline-none"
            >
              <option disabled value="">Choisissez</option>
              <option
                v-for="key in Object.keys(
                  $t(`property.details.ground_area_type`)
                )"
                v-bind:key="key"
                v-bind:value="key"
              >
                {{ $t(`property.details.ground_area_type.${key}`) }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-start mb-3">
        <div
          class="self-start rounded-full text-primary transform -translate-y-2px"
        >
          <Icon size="md" name="car" />
        </div>
        <div class="ml-2 flex flex-col items-start font-sans text-left">
          <span class="mb-1 text-xs text-gray-elephant">
            {{ $t("property_labels.parking_type") }}
          </span>
          <span
            v-if="!editMode"
            class="text-sm text-gray-smoke whitespace-pre-line mr-2"
          >
            {{
              search.details.parking_type == null
                ? $t("not_applicable")
                : $t(
                    `property.details.parking_type.${search.details.parking_type}`
                  )
            }}
          </span>
          <div
            v-else
            class="flex items-center p-1 bg-gray-light rounded-xxl w-full"
          >
            <select
              v-model="search.details.parking_type"
              class="pr-1 bg-transparent font-sans font-medium text-sm text-gray-smoke outline-none"
            >
              <option disabled value="">Choisissez</option>
              <option
                v-for="key in Object.keys($t(`property.details.parking_type`))"
                v-bind:key="key"
                v-bind:value="key"
              >
                {{ $t(`property.details.parking_type.${key}`) }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:w-1/2 lg:pl-4 lg:border-l-2 lg:border-gray-mouse">
      <div class="flex flex-row justify-start mb-3">
        <div
          class="self-start rounded-full text-primary transform -translate-y-2px"
        >
          <Icon size="md" name="comments" />
        </div>
        <div class="ml-2 flex flex-col items-start font-sans text-left w-full">
          <span class="mb-1 text-xs text-gray-elephant">
            {{ $t("property_labels.details") }}
          </span>
          <span
            v-if="!editMode"
            class="text-sm text-gray-smoke whitespace-pre-line mr-2"
          >
            {{
              search.details.details == null
                ? $t("not_applicable")
                : search.details.details
            }}
          </span>
          <div
            v-else
            class="flex items-center p-1 bg-gray-light rounded-xxl w-full"
          >
            <textarea
              v-model="search.details.details"
              class="p-2 bg-transparent font-sans font-medium text-sm text-gray-smoke outline-none resize-none"
              style="line-height: 1.5; width: 100%; max-width: 100%;"
              cols="20"
              rows="11"
              data-min-rows="11"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PropertyDetails",

  props: {
    search: {
      type: Object
    },

    editMode: {
      type: Boolean,
      default: false
    }
  }
};
</script>
